import React from "react";
import { ServicesStyled } from "./ServicesStyle";


function ServicesList() {
    return (
    
    <>
    <ServicesStyled>
      
    
        <p>
        In today's fast-paced business landscape, small companies like yours need a competitive edge to thrive, streamline operations, reduce labor costs, and achieve newfound efficiency. The costs associated with developing tailored solutions to address your business's unique challenges have been significantly reduced with the advent of AI. Implementing your ideas is now easier than ever before. Don't let manual processes hold you back from realizing your true potential. 
        Contact us now for a personalized consultation and experience the power of automation for your business.
</p>    
      
    </ServicesStyled>  
    </>
  )
  }
  export default ServicesList;