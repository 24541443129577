import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BodyStyled, SmallText } from "./Body.styled";


function LargeCard() {
  return (
  
  <>
  <BodyStyled>
    <p>Empowering Small Businesses through Automation </p>
  
    <SmallText>
    <p>Eliminate.Automate.Delegate.</p>
    </SmallText>
  </BodyStyled>  
  </>
)
}
export default LargeCard;