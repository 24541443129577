import React from "react";
import { FooterStyled, ContainerBg } from "./Footer.styled";


function Footer() {
  return (
  
  <>
  <ContainerBg>
  <FooterStyled>
    <p>Justified Development, LLC</p>
    <p>Noblesville, IN</p>
    <p>Crystal Lake, IL</p>
  
    
  </FooterStyled> 
  </ContainerBg> 
  </>
)
}
export default Footer;

